.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex > * {
    margin-left: 7px;
}

.flex > *:first-child {
    margin-left: 0;
}

.logo svg {
    width: 35px;
}

.header {
    padding: 0 1.5em;
    background-color: #1890cb;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    position: absolute;
    width: 100%;
    height: 4em;
    z-index: 1000;
}

.header a {
    color: #fff !important;
    font-size: 1.2em;
    text-decoration: none;
    margin: 0 0.6em;
}

.header a:active {
    color: #fff;
}

.header .btn {
    margin: 0.5em 0;
    padding: 12px;
}

.header .app-selector {
    display: flex;
    width: 160px;
    font-size: 1.1em;
    text-align: left;
    cursor: pointer;
    align-items: center;
    border: 1.5px solid rgba(255, 255, 255, 0.5);
    border-radius: 5px;
    padding: 1.5px 5px;
    margin: 0 .4em;
    background-color: transparent;
    text-transform: none !important;
}

.header .app-selector .title {
    flex-grow: 2;
}

.header .app-selector h1,
.header .app-selector h3 {
    margin: 0;
    padding: 0;
}

.header .app-selector h1 {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 0.8;
    margin-top: 2px;
    color: rgba(255, 255, 255, 1);
}

.header .app-selector h3 {
    font-size: 0.9em;
    color: rgba(255, 255, 255, 0.8);
}

.app-selector.dropdown-toggle::after {
    border-width: 6px;
    vertical-align: middle;
}

.header .app-selector .down-arrow svg {
    font-size: 15px !important;
    color: #fff;
}

.logo {
    height: 100%;
}

select {
    background: none;
    border: none;
    font-size: 3em;
    font-weight: 500;
    margin-left: 1em;
    width: auto;
    border: 1px solid #bbb;
    cursor: pointer;
    padding: 0.3em 0.5em;
    border-radius: 3px;
}

optgroupoption {
    font-size: 2em;
}

.user-name {
    color: #fff;
}