.dropup,
.dropdown{
    .dropdown-toggle:after{
        margin-left: 3px;
    }

    .dropdown-item.active{
        color: $white-color !important;
        background-color: $info-color;
    }
}
