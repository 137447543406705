.configuration-card { 
    height: fit-content;
    margin: 10px;
    border: 2px solid transparent;
    width: 430px;
}

.configuration-card .configuration-body {
    padding: 0px;
}

.configuration-card .configuration-header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    margin: 0 1em;
    align-items: center;
    padding: 8px 0 5px 0;
}

.configuration-card .configuration-header .product-model {
    font-weight: bold;
}

.configuration-card .configuration-header .product-cost {
    flex-grow: 2;
    padding-left: 5px;
    text-align: left;
}

.configuration-card .configuration-header .product-actions {
    display: flex;
    flex-direction: row;
}

.configuration-body .configuration-details-container {
    font-size: 14px;
    padding: 5px;
}

.configuration-body .total {
    height: 20px;
    padding: 1px;
    border-bottom-left-radius: 5px;

    background-color: #F2F2F2;
    border: 1px solid #000;

    font-size: 12px;
}

.configuration-content {
    padding-right: 10px;
}

.configuration-details > p {
    margin: 0px;
}

.configuration-actions button {
    width: 100%;
    height: 25px;

    padding: 0px;
    
    font-size: 14px;
}

.configuration-detail-item {
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0px 10px 7px 0px
}

.configuration-detail-item > p{
    margin: 0px;
    font-size: 12px;
    line-height: 0.8;
    text-align: left;
    height: 100%;
    align-content: end;
}

.configuration-header button {
    border-radius: 4px;
    border: none;
    background: none;
    padding: 4px 0.6em;
    display: inline-block;
}

.configuration-header button.basic {
    margin-right: 5px;
}

button.basic {
    border-radius: 4px;
    border: none;
    background: none;
}

button.basic:hover {
    background-color: rgba(0, 0, 0, 0.06);
}

button.basic:active {
    background-color: rgba(0, 0, 0, 0.12);
}

.details-info-content button.selected,
.details-info-content button.not-selected {
    /* font-weight: bold; */
    border: 1px solid #1890cb;
    border-radius: 5px;
}

button.selected {
    background-color: #1890cb;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
}

button.selected:hover {
    color: rgba(255, 255, 255, 0.9);
}

button.selected:active {
    color: rgba(255, 255, 255, 0.75);
}

button.not-selected:hover {
    background-color: #e2f5ff;
}

button.not-selected:active {
    background-color: #b4e5ff;
}

button.not-selected {
    background: none;
    color: #1890cb;
    border: 1px solid #1890cb;
}

button.locked {
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.featured-item {
    color: #1890cb;
    margin-right: 5px;
}

.long-model-name {
    font-size: 0;
    padding-right: 4px;
}

.warning-card {
    border: 3px solid #f55 !important;
}

.high-redundancy {
    background-color: #f0fbf8 !important;
}