.page.tools {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.tools-sidebar {
    background-color: #eee;
    width: 180px;
    padding: 1em 0.5em;
    border-right: 1px solid #ccc;
    flex-shrink: 0;
}

.tools-sidebar a {
    text-decoration: none;
    /* background-color: rgba(0, 0, 0, 0.05); */
    border-radius: 6px;
    display: block;
    padding: 0.5em 0.7em;
}


.tools-content {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.tools-content form {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 1em 1.5em;
    height: calc(100vh - 56px);
    border-right: 1px solid #ccc;
    width: 240px;
    flex-shrink: 0;
    overflow-y: auto;
}

.tools-content form button {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    display: block;
    padding: 0.5em 0.7em;
    margin-top: 1em;
    color: #fff;
}

.tools-content .results {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    height: calc(100vh - 56px);
    padding: 1em;
    overflow-y: auto;
    overflow-x: auto;
}

.loading {
    text-align: center;
    padding: 2em;
}

.no-results {
    text-align: center;
    padding: 0.5em;
    margin: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.config-data {
    width: 95%;
    max-width: 1000px;
    margin: 1em auto;
}

.config-data h3 {
    font-size: 1em;
    margin: 0.8em 0 0.4em;
    padding: 0;
    font-weight: bold;
}

.active {
    background-color: #ddd;
}

.flats-display {
    /* border: 1px solid #000; */
    flex-grow: 2;
    padding: 0 1rem;
}

.flats-display canvas {
    margin: 0 auto;
    display: block;
}

.flats-data {
    padding-top: 0.5rem;
}

.flats-data table {
    border: 1px solid #333;
    width: 300px;
}

.flats-data table th {
    border-bottom: 1px solid #666;
}

.flats-data table th {
    text-align: center;
    background-color: #ddd;
}

.flats-data table td {
    padding: 0.4rem;
}

.flats-data table td.centered {
    text-align: center;
}

.flats-data-inset {
    padding-left:1.7rem;
}

.flats-flange-image {
    display: flex;
    font: 13px sans-serif;
    font-weight: bold;
    align-items: center;
    margin-top: 3rem;
}

.flats-flange-image img {
    max-width: 400px;
    height: auto;
}

.flats-side-label {
    align-content: center; 
    padding: 0px 5px;
}

.flats-result {
    display: flex;
    flex-direction: row;
}

.flange-depth {
    margin-top: 0.7rem;
}

.collapse-open {
    transition: all 150ms linear;
    height: 25px;
}

.tools-content .collapse-closed {
    transition: all 150ms linear;
    overflow: hidden;
    height: 0px;
    margin-top: -1.2em;
}

.search-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.job-entry {
    width: 90%;
}

.job-holder {
    width: 90%;
    display: flex;
    padding-bottom: 5px;
}

.search-row-holder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.search-row-label {
    display: flex;
    width: 50%;
    background-color: #D8D8D8;
    font-weight: 600;
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.search-row-property {
    background-color: #F2F2F2;
    width: 50%;
    padding-left: 10px;
    padding-top: 2px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-right: 3px;
    height: 25px;
    overflow-y: auto;
}

.found-property {
    font-weight: 650;
}

.downloader-section {
    background-color: #D8D8D8;
    border-bottom: 3px solid white;
    font-weight: 600;
    width: 10%;
    border-radius: 3px;
    justify-content: center;
    display: flex;
    justify-content: center;
    padding-top: 2px;
}

.header-arrow {
    align-self: center;
    padding-left: 5px;
    padding-right: 3px;
    font-size: 16px;
}

.rotated-arrow {
    align-self: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    rotate: 90deg;
}

.clickable {
    cursor: pointer;
}

.row-spacer {
    height: 3px;
}

.center-message {
    align-self: center;
    top: 30%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20pt;
}

.center-message .spinner-border {
    height: 80px;
    width: 80px;
    margin-top: 20px;
}

.small-text{
    font-size: 12px;
}

.last-collapse{
    margin-bottom: 0.6em;
}

.clipped-highlighted {
    background-color: yellow;
    font-weight: bold;
    clip-path: inset(3px 3px round 4px);
}

.coil-button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: auto;
}

.coil-button-group .icon {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    font-size: 1.1em;
    margin-left: 5px;
}

.coil-button-group .icon:hover {
    background-color: rgba(0, 0, 0, 0.7);;
    color: white;
    border-radius: 4px;
}

.coils-content {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    overflow-x: auto;
}

.input-border-right {
    border-right: 2px solid #d3d7d7 !important; 
}

.input-container-group {
    margin-bottom: -0.7em;
}

.input-group input, .input-group-text {
    height: 32px !important;
}

.input-group > .form-control:first-child {
    border-right: 2px solid #d3d7d7 !important; 
}

.input-group > .form-control:nth-child(2) {
    border-radius: 0 5px 5px 0px !important; 
}

.input-container label Input {
    font-size: 1em;
    margin-top: 0.2em;
    margin-right: 5px;
}

.coil-header, .coil-header-top {
    font-size: 1.04em;
    font-weight: bold;
    margin-bottom: .3em;
    margin-top: 1.6em;
    align-self: left;
}

.coil-header-top {
    margin-top: 0.2em;
}

.coil-results {
    flex: 1;
    background-color: #ddd;
    min-height: calc(100vh - 7.5em);
    padding: 5px;
}

.coil-results .loading, .coil-results .error {
    text-align:center;
    padding: 3em;
    font-size: 1.1em;
}

.error-icon {
    color: #fb3939;
    font-size: 1.2em;
}

.results-card { 
    height: fit-content;
    margin: 10px;
    border: 2px solid transparent;
    width: 480px;
}

.results-card .result-body {
    padding: 0px;
}

.results-card .results-header {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ccc;
    margin: 0 1em;
    align-items: center;
    padding: 12px 0 8px 0;
}

.results-card .results-header .product-model {
    font-weight: bold;
    font-size: 1.1em;
}

.result-body .result-details-container {
    font-size: 15px;
    padding: 5px;
}

.result-content {
    padding-right: 10px;
}

.extend-width {
    width: 490px;
}

.extend-width .result-details .result-detail-item:nth-child(2) {
    width: 240px;
}

.result-details > p {
    margin: 0px;
}

.result-detail-item {
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content:left;
    align-items: flex-start;
    margin: 0px 10px 12px 0px
}

.result-detail-item > p{
    margin: 0px;
    font-size: 14px;
    line-height: 0.8;
    text-align: left;
    height: 100%;
    align-content: end;
}

:root {
    --form-max-width: 900px;
    --form-min-width: 400px;
}

.form-container {
    width: 100%;
    max-width: var(--form-max-width);
    min-width: var(--form-min-width);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 1em;
    border-right: 1px solid #ccc;
}

.input-row {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 12px;
    align-items: center;
}

.input-container, .input-container-group {
    width: 100%;
}

.large-textarea {
    width: 100% !important;
    min-height: 300px !important;
    height: 100px !important;
    padding: 8px !important; 
    box-sizing: border-box !important; 
}

.coil-button {
    background-color: rgba(0, 0, 0, 0.8);
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    display: block;
    padding: 0.5em 0.7em;
    color: #fff;
    /* align-self: center; */
    width: 30%;
}

.modal-button {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 6px;
    display: block;
    padding: 0.5em 0.7em;
    width: 20%;
    cursor: pointer;
}

.modal-button:hover {
    color: rgb(253, 255, 255);
    background-color: rgba(0, 0, 0, 0.8);
}

.button-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-top: 1em;
    gap: 1em;
}

.tooltip-container {
    display: block;
    max-width: 400px;
    text-align: left;
    max-height: 60px;
    pointer-events: none;
    z-index: 1575;
}

.tooltip-container-price {
    display: block;
    max-width: 400px;
    text-align: left;
    max-height: 120px;
    pointer-events: none;
    z-index: 1575;
}

.filter-form {
    display: flex;
    flex-direction: column;
    gap: 0.6em;
    padding: 1em 1.5em;
    height: calc(100vh - 56px);
    flex-shrink: 0;
    overflow-y: auto;
    border-right: 1px solid #ccc;

}

.filter-collapse-open {
    transition: all 150ms linear;
    height: 56px;
}

.collapse-open-pressure-drop {
    transition: all 150ms linear;
    height: 32px;
}

.filter-collapse-closed {
    transition: all 150ms linear;
    overflow: hidden;
    height: 0px;
    margin-top: -0.5em;
}

.door-options-div {
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.door-options-div::before,
.door-options-div::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.door-options-div::before {
    left: 0%; 
    right: 50%; 
    margin-right: 8px; 
}

.door-options-div::after {
    right: 0%;
    left: 50%;
    margin-left: 8px;
}

.door-label {
    padding: 0 8px;
    background-color: white;
    z-index: 1;
}

.caret-icon {
    transition: transform 0.3s ease;
    margin-top: 0px;
    margin-right: 8px;
    font-size: 12px;
}

.caret-icon.open {
    transform: rotate(90deg);
}

.radio-input {
    margin: 5px 5px 0 5px;
}

.collapse-open-door-options {
    transition: all 150ms linear;
    height: 64px;
} 

.collapse-closed-door-options {
    transition: all 150ms linear;
    overflow: hidden;
    height: 0px;
    margin-top: -0.5em;
}

.filter-checkbox {
    margin-right: 7px;
    margin-left: 5px;
}

.filter-result {
    flex: 1;
    /* background-color: #ddd; */
    background-color: #eeeeee;
    /* min-height: calc(100vh - 1em); */
}

.filter-result-items {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 40%);
}

.filter-table-container { 
    /* height: fit-content; */
    /* margin: 10px; */
    /* border: 2px solid transparent; */
    padding: 2.5em 1.5em;
    min-width: 780px !important;
    max-width: 1280px !important;
    width: 70%;
    border-radius: 2px !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.filter-table {
    text-align: center; 
}

.filter-table thead {
    border-bottom: 1px solid #333;
}

.filter-table thead th {
    padding-top: 4px !important;
    line-height: 1.2 !important;
    text-transform: none !important;
}

.filter-table thead tr:nth-child(1) th  {
    font-size: 14px !important;
    padding-bottom: 0px !important;
    vertical-align: bottom !important;
}

.filter-table thead tr:nth-child(2) th  {
    font-size: 12px !important;
    padding-bottom: 10px !important;
    vertical-align: top !important;
}

.selected-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2.5em 1em;
    width: 27.5%;
    max-width: 425px;
    min-width: 335px !important;
    border-radius: 2px !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.filter-button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -1.5em;
    width: 100%;
}

.filter-button-group .filter-icon {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.7);
    border: none;
    transition: background-color 0.2s ease;
    font-size: 1.2em;
    border-radius: 4px;
    padding-left: 6px
}

.filter-button-group .filter-icon:hover {
    background-color: rgba(0, 0, 0, 0.7);;
    color: white;
}

.selected-filter-header {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    width: 100%;
    padding-top: .2em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    /* border-bottom: 1px solid #333; */
}

.selected-filter-layout {
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 285px;
    min-width: 285px;
    max-height: 335px;
    max-width: 285px;
}

.selected-filter-stats {
    padding-top: 2em;
    align-self: center;
    width: 100%;
}

.stats-row-container {
    display: flex;
    align-items: center;
    border-bottom: 3px solid #fff;
    background-color: #F2F2F2;
}

.stats-row-container span {
    padding: 5px 3px 5px 10px;
}

.stats-row-label {
    display: flex;
    width: 50%;
    background-color: #D8D8D8;
    font-weight: 600;
}

.filter-table button {
    border-radius: 4px;
    padding: 3px 5px;
    margin-top: 0px;
    margin-right: 5px;
    width: 55px;
}

.tools-content.selected {
    background-color: #1890cb !important;
    color: #fff !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
}

.tools-content .selected:hover {
    color: rgba(255, 255, 255, 0.9) !important; 
}

.tools-content .selected:active {
    color: rgba(255, 255, 255, 0.75) !important;
}

.tools-content .not-selected:hover {
    background-color: #e2f5ff !important;
}

.tools-content .not-selected:active {
    background-color: #b4e5ff !important;
}

.tools-content .not-selected {
    background: none !important;
    color: #1890cb !important;
    border: 1px solid #1890cb !important;
}

.flex-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.flex-column-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-height: 1500px) {
    :root {
        --form-max-width: 800px;  
    }
    .input-row {
        grid-template-columns: repeat(3, 1fr); 
    }
    .button-row {
        margin-top: 3em;
    }
    .coil-header, .coil-header-top {
        margin-top: 2em;
    }
    .coil-header-top {
        margin-top: 0em;
    }
    .selected-filter-container, .filter-table-container {
        padding: 3em 2em;
    }
    .tools-sidebar {
        width: 200px;
    }
    .filter-form {
        min-width: 266px;
    }
}

@media (max-height: 1150px) {
    :root {
        --form-max-width: 1000px; 
    }
    .input-row {
        grid-template-columns: repeat(5, 1fr); 
    }
    .button-row {
        margin-top: 1.5em;
    }
    .coil-header {
        margin-top: 1.5em;
    }
    .coil-header-top {
        margin-top: 0em;
    }
    .selected-filter-container, .filter-table-container {
        padding: 2.5em 1.5em;
    }
    .tools-sidebar {
        width: 180px;
    }
    .filter-form {
        min-width: 266px;
    }
}

.re-submit-needed {
    color: #939322;
    background-color: #f8f8d7;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-weight: 700;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    align-content: center;
    text-align: center;
}