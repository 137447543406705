.expanded-configuration-details-container {
    background-color: #fff;
    min-height: calc(100vh - 9.5em);
    position: relative;
    /* border-radius: 6px 6px 0 0; */
}

.stats-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 5px;
    border-bottom: 1px solid #B8B8B8;
}

.stats-header span {
    width: 100%;

    font-weight: bold;
    padding: 5px 20px;
    background-color: #E6E6E6;
}

.stat-row-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 3px solid #fff;
    background-color: #F2F2F2;
}

.stat-row-container span {
    /* color: #555555; */
    padding: 5px 10px;
}

.state-row-label {
    display: flex;
    width: 50%;
    background-color: #D8D8D8;
    font-weight: 600;
}

.sytem-sound-power-table {
    width: 100%;
    overflow-y: hidden;
    margin-bottom: 5em;
}

.sytem-sound-power-table tr {
    border-bottom: 1px solid #fff;
}

.sytem-sound-power-table-header {
    width: 100%;
    display: flex;
    font-style: normal;
    font-weight: 700;
    padding: 3px 3px 4px;
    background-color: #D8D8D8;
    justify-content: center;
}

.system-sound-power-table-data {
    width: 100%;
    padding: 3px 3px 4px;
    background-color: #F2F2F2;
    text-overflow: ellipsis;
}

.details-info {
    position: fixed;
    right: 0px;
    left: 280px;
    z-index: 300;
    background-color: #ddd;
}
.details-info .top-space {
    height: 0px;
}

.details-info-content {
    display: flex;
    align-items: center;
    text-align: left;
    height: 3.5em;
    gap: 1em;
    font-weight: normal;
}

.details-info-content > div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.details-info .price {
    flex-grow: 2;
    padding-left: 5px
}

.details-info-content button {
    border-radius: 4px;
    border: none;
    padding: 6px 0.6em;
    font-size: 1.1em;
    text-transform: unset;
}

.details-info-content button svg {
    margin-right: 0.5em;
}

.details-content {
    padding-top: 0.5em;
    background-color: #fff;
    min-height: calc(100vh - 7.5em);
    /* border-radius: 6px 6px 0 0; */
}

.details-content .tainer {
    margin: 0 auto;
    padding: 0 1em;
    max-width: 1000px;
    overflow-x: auto;
}

.details-content h2,
.details-content h3 {
    margin: 0;
    font-size: 1em;
    padding: 0.3em 0.3em 0.4em;
    margin-bottom: 3px;
    font-weight: 700;
    /* text-transform: uppercase; */
}

.details-content h2 {
    background-color: #1A4061;
    color: rgba(255, 255, 255, 0.85);
}

.details-content h3 {
    background-color: #B8CCE4;
    color: rgba(0, 0, 0, 0.85);
}

.details-content-actions {
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.config-details-container {
    /* padding: 10px; */
    padding-bottom: 0;
    padding-top: 0;
    background-color: #ddd;
    border-left: 1px solid #ccc;
    /* border-radius: 6px 6px 0 0; */
}

.post-config-info-holder {
    width: 100%;
    height: 170px;
}

.post-config-row-holder {
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: space-between;
}

.post-config-column-holder {
    display: flex;
    justify-content: space-around;
    padding: 0px 10px;
}

.post-config-column {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 54%;
}

.post-config-column-right {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 46%;
}

.post-config-column-right-hidden {
    height: 0px;
    overflow: hidden;
}

.post-config-column:nth-of-type(2) .post-config-padded {
    padding-left: 10px;
}

.post-config-padded {
    padding-top: 3px;
}

.post-config-update-holder {
    padding-right:10px;
}

.post-config-column:nth-of-type(2) input {
    width: 160px;
}

.small-text {
    font-size: 8pt;
}

.cost-breakdown-table tr:nth-child(n+2){
    border-top: 2px solid rgb(236, 236, 236);;
}

.cost-breakdown-table tr:last-child{
    border-top: 1px solid black;
}

.final-row {
    border-top: 1px solid #000;
}

.cost-overview-value {
    text-align: right;
    padding-left: 1em;
}

.strickenthrough {
    text-decoration: solid line-through black;
    text-decoration-thickness: 1.5px;
}

.invalid-config-warning {
    color: #dc3545;
    background-color: #f8d7da;
    font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif; 
    font-style: normal;
    font-weight: 700;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    align-content: center;
}

.icon-sides-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.update-button-collapse-open {
    display: flex;
    transition: all 150ms linear;
    height: 35px;
}

.update-button-input-open {
    display: flex;
    transition: all 150ms linear;
    height: 20px;
}

.adjust-button-up {
    margin-top: -35px;
}

.collapse-closed {
    display: flex;
    transition: all 150ms linear;
    overflow: hidden;
    height: 0px;
    padding-top: 8px;
}

.end-positioned {
    justify-content: flex-end;
}

.voltage-override-section {
    display:flex;
}

.voltage-override-section input::placeholder{
    color: #999;
}

.voltage-override-section input:disabled{
    background-color: #E3E3E3;
    color: #284B63;
    cursor: default;
}

.clear-voltage-button {
    margin-left:5px;
    padding: 0px 5px;
    border-radius: 3px;
    background-color: #dc3545;
    border: 2px solid #dc3545 !important;
    color: #fff;
}

.clear-voltage-button:disabled {
    margin-left:5px;
    padding: 0px 5px;
    border-radius: 3px;
    background-color: #B8B8B8;
    border: 2px solid #B8B8B8 !important;
    color: #fff;
}

.grayed-out {
    color: #aaa !important;
}