.inputs-container {
  height: 100%;
  text-align: left;
  padding: 1em 1em 1em 2em;
  width: 280px;
  min-height: 100%;
  /* position: sticky; */
  top: 105px;
  overflow-y: auto;
  bottom: 0;
}

.configurations-manager {
  display: flex;
  padding-top: 3.5em;
  min-height: 100%;
  flex-wrap: nowrap;
  min-width: 1261px
}

.inputs-container input:disabled{
  cursor: default;
}

.inputs-container select:disabled{
  background-color: #E3E3E3;
  color: #284B63;
  cursor: default;
}

.inputs-container-disabled-label{
  cursor: default !important;
}

.configurations.page {
  min-height: 100vh;
}

.configurations-container {
  flex: 1;
  background-color: #ddd;
  min-height: calc(100vh - 7.5em);
}

.configuration-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 1em;
}

.narrow-configurations {
  justify-content: center;
}

.empty {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #E6E6E6;
  justify-content: center;
  align-items: center;
}

.actions {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  align-items: center;
  padding: 0.5em 1.5em;
  position: absolute;
  width: 100%;
  z-index: 500;
  height: 3.3em;
  font-size: 1.1em;
  font-weight: 700;
}

.actions .separator {
  margin: 0 0.4em;
  font-size: 0.9em;
}

.actions .job-link {
  text-decoration: none;
  cursor: pointer;
}

.actions .job-link:hover {
  text-decoration: underline;
}

.actions .job-link.active:hover {
  text-decoration: none !important;
}

.actions select {
  font-size: 1em !important;
}

.save-button {
  padding: 0.75em 1em !important;
}

.grow {
  flex-grow: 2;
}

.options-inputs, .display-inputs {
  margin-top: 1em;
}

.options-inputs label, .display-inputs label {
  margin-bottom: 0.3em;
  cursor: pointer;
}

.hide-me {
  display: none;
}

.inputs-container .mb-3 {
  margin-bottom: 0.8em !important;
}

.input-section {
  margin-top: 0.5em;
}

.input-section ul {
  padding-left: 1em;
}

#declutter-tip {
  color:gray;
  padding-right:7px;
}

.filters-container {
  display: flex;
  border-left: 1px solid #00000030;
  text-align: left;
}

.filters-holder {
  width: 240px;
  padding: 1em;
}

.filters-sidebar {
  height: 100%;
  background-color: #9292a9;
  color: #F0F0F4;
  font-size: 1.2em;
  font-weight: 500;
  letter-spacing: 2px;
  width: 30px;
  cursor: pointer;
}

.filters-arrow {
  padding-left: .5em;
  padding-top: .3em;
}

.arrow-down {
  rotate: 90deg;
  padding-left: .8em;
}

.filters-sidebar-text {
  rotate: 90deg;
  padding-left: 2px;
}

.filters-sidebar-text-down {
  rotate: 90deg;
  padding-left: 3px;
}

.checkbox-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: .5em;
  padding-right: .5em;
}

.checkbox-holder label {
  padding-right: 5px;
}

.checkbox-holder input {
  margin-right: 5px;
}

.filter-number-holder {
  padding-left: .5em;
  padding-right: .5em;
}

.filter-number-two-wide {
  display: flex;
}

.update-button {
  cursor: pointer;
  color: #fff;
  background-color: #1890cb;
  border: 1px solid #1890cb;
  border-radius: 4px;
  padding: 4px 0.6em;
  display: inline-block;
  font-family: "Montserrat", "Helvetica Neue", Arial, sans-serif; 
  transition: all 150ms linear;
}

.update-button:disabled {
  cursor: default;
  background-color: #D8D8D8;
  border: 1px solid #D8D8D8;
}

.update-icon {
  padding-right: 5px;
}

.update-button:hover {
  background-color: #284B63;
}

.update-button:disabled:hover {
  background-color: #D8D8D8;
}

.collapse-open {
  transition: all 150ms linear;
  height: 25px;
}

.collapse-closed {
  transition: all 150ms linear;
  overflow: hidden;
  height: 0px;
}

.radio-holder {
  margin: 0px 5px;
}

.radio-button {
  margin: 0px 2px;
}

.update-holder {
  justify-content: flex-end;
  display: flex;
  padding-top: 5px;
  height: 40px;
  transition: all 150ms linear;
}

.update-holder-closed {
  justify-content: flex-end;
  display: flex;
  padding-top: 0px;
  height: 0px;
  overflow: hidden;
  transition: all 150ms linear;
}

.streaming-loading-div {
  background-color: #FFFFFFBB;
  position: sticky;
  inset: 0px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
}

.streaming-loading-item {
  align-self: center;
  padding: 0px 7px;
}

.egg-holder {
  height: 100%;
  width: 100%;
  background-color: #ddd;
}

.show-egg {
  animation: .5s linear .5s 6 normal forwards running egg;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0%;
}

@keyframes egg{
  0% {
      display: none;
      opacity: 0;
  }
  1% {
    display: block;
    opacity: 0
  }
  50% {
      opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
}