.btn:disabled {
    color: $disabled-color;
    background-color: $disabled-bg-color;
}

.btn {
    padding: 16px;
    font-size: 12px;
}

.btn > svg {
    width: 12px;
}