.product-actions {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    justify-items: end;
    opacity: 0;
}

.product-actions button {
    display: inline;
    background: none;
    border: none;
    width: 30px;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.product-actions button:enabled:hover,
.product-actions button:active {
    display: inline;
    background: none !important;
    border: none;
    width: 30px;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: #000 !important;
    border-radius: 5px;
}

.quote-product {
    margin-bottom: 0.5em;
}

.quote-product:hover .product-actions {
    opacity: 1;
}

.quantity {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8em;
    justify-content: center;
    color: #666;
    padding-right: 4px;
}

.quantity-value {
    border-bottom: 1px dotted #333;
    cursor: pointer;
}

.quantity-value-disabled {
    border-bottom: 1px dotted darkgray;
}

.quantity-cell {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.system-header td {
    margin-bottom: 5px;
}

.product-options td {
    margin-top: 5px;
}

.system-info,
.selected-configuration {
    line-height: 1.2;
}

.highlighted {
    background-color: yellow;
    font-weight: bold;
    clip-path: inset(0px);
}

.clipped-highlighted {
    background-color: yellow;
    font-weight: bold;
    clip-path: inset(4px 4px round 5px);
}