.expandable {
    background-color: #e6e6e6;
}

.quote-total {
    padding: 5px;
    border: 1px solid #DEE2E6;
    border-radius: 5px;

    background-color: #fff;
}

.quote-total p {
    margin: 0px;
}

.page-title {
    text-align: left;
    align-items: center;
}

.page-title input {
    flex-grow: 2;
}

.page-title h4 {
    margin: 0;
}

table {
    background-color: #fff;
}