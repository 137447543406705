body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
}

body,
html,
#root,
.App {
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table>thead>tr>th {
  font-size: 12px;
}

#hidden-canvas {
  display: none;
}

.page {
  padding-top: 4em;
  min-height: 100vh;
}

.form-select {
  font-size: 1em !important;
}