.job-actions {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    display: flex;
    padding: 0.5em 1.5em;
    text-align: left;
    font-size: 1em;
    align-items: center;
    height: 3.5em;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 300;
}

.job-actions h2 {
    padding: 0;
    margin: 0;
    font-size: 1.1em;
    font-weight: 700;
    line-height: 1;
    flex-grow: 2;
}

.job-actions h4 {
    padding: 0;
    margin: 0 0 0 0.5em;
    flex-grow: 2;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1;
}

.job-actions button {
    padding: 0.75em 1em;
    margin: 0;
}

.job-quotes {
    display: flex;
    overflow-x: auto;
    height: 100%;
    padding: 0 1.5em;
    overflow-y: hidden;
}

.job-quotes .quote {
    /* display: inline-block;
    background-color: #fff;
    border-radius: 5px; */
    /* padding: 1em; */
    text-align: left;
    flex: 0 0 485px;
    /* min-width: 40%; */
    width: 485px;
    box-sizing: border-box;
    padding: 10px;
    overflow-y: scroll;
    overflow-y: overlay;
    /* border: 1px solid #ddd; */
    /* border-bottom: 1px solid #bbb; */
}

.empty-quote.single {
    text-align: left;
    flex: 0 0 485px;
    width: 485px;
    box-sizing: border-box;
    overflow-y: scroll;
    margin-top: 1em;
    text-align: center;
}

.quote-unselected {
    opacity: 40%;
    transition: all 150ms linear;
}

.quote-unselected:hover {
    opacity: 60%;
}

.quote-header {
    display: flex;
    margin-bottom: 0.25em;
    flex-basis: auto;
    justify-content: space-between;
}

.quote-header-title {
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.job-number {
    font-weight: 900;
    font-size: 14pt;
}

.job-number svg {
    font-size: 10pt;
    margin-left: 0.5em;
    cursor: pointer;
}

.quote-header-button-holder {
    display: flex;
    flex-direction: column;
}

.quote-header-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

button-disableable:disabled:hover {
    background-color: rgb(0,0,0,0) !important;
}

button:disabled {
    color: rgba(0, 0, 0, 0.3) !important;
}

.untransmit-button {
    color: rgba(200, 0, 0, 0.7) !important;
}

.quote-header h4 {
    margin: 0;
    font-size: 1.1em;
    font-weight: normal;
    margin-bottom: 5px;
    flex-grow: 2;
    margin-left: 0.5em;
}

.quote-header .dropdown button {
    margin: 0;
}

.empty-quote>button,
.quote-header button,
.quote-footer button {
    display: block;
    background: none;
    border: none;
    padding: 0.4em 0.8em;
    font-size: 0.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
}

.empty-quote>button {
    margin-top: 1em;
}

.empty-quote {
    min-height: 70vh;
    background: rgb(238, 238, 238);
    background: linear-gradient(180deg, rgba(238, 238, 238, 1) 0%, rgba(206, 217, 223, 0) 100%);
    text-align: center;
    border-radius: 5px;
}

.empty-quote>button {
    display: inline-block;
}

.quote-header-buttons button:enabled:hover,
.quote-footer-transmit-button button:enabled:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.job-quotes .quote-product-list table {
    width: 100%;
}

.job-quotes .quote-product {
    padding: 0.5em 1em 0.8em 1em;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.product-options td {
    padding-top: 8px;
}

.product-options td>* {
    background-color: rgba(0, 0, 0, 0.075);
    margin-right: 0.5em;
    display: inline-block;
    border-radius: 5px;
    padding: 0.2em 0.6em;
}

.quote-header {
    display: flex;
    margin-bottom: 0.25em;
}

.quote-header h4 {
    flex-grow: 2;
}

.product-link {
    font-size: 1.2em;
    font-weight: bold;
}

.product-link-disabled {
    font-size: 1.2em;
    font-weight: bold;
    color: #284b63;;
}

.job-quotes-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 4em;
    bottom: 0;
    padding-top: 3.5em;
    overflow-y: hidden;
}

.quote-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 1em;
    padding-left: 1em;
}

.quote-footer-buttons {
    display: flex;
}

.transmitted {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.transmitted-value {
    margin-left: 10px;
    margin-right: 10px;
}

.flex-break {
    flex-basis: 100%;
}

.transmitted-info {
    width: 35%;
    text-align: end;
    align-content: center;
}

.bottom-section {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
}

.option-labels-small {
    width: 65%;
}

.option-label {
    margin: 0.2em 0.25em;
    display: inline-block;
    border-radius: 5px;
    padding: 0.2em 0.6em;
}

.hidden-enabled {
    display: none;
}

.properties-holder {
    width: 100%;
}

.properties-holder tr:nth-child(odd) {
    background-color: rgb(236, 236, 236);
}

.properties-holder tr>td:first-of-type {
    font-weight: 600;
}

.properties-holder tr>td:last-of-type {
    text-align: end;
}

.properties-holder tr {
    line-height: 25px;
}

.controls-modal-serials {
    overflow-y: auto;
    max-height: 80px;
}

.quote-button {
    height: 30px;
    margin-bottom:0px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: transparent;
    border: 2px solid #284B63 !important;
    color: #284B63;
    transition: all 150ms linear;
    font-size: 0.8em;
    padding: 0.2em 0.5em;
}

.quote-footer-button {
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: transparent;
    border: 2px solid #284B63 !important;
    color: #284B63;
    transition: all 150ms linear;
    font-size: 0.8em;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
}

.quote-button:hover, .quote-footer-button:hover {
    background-color: #284B63;
    color: #FFFFFF;
    border: none;
}

.text-beside-svg {
    padding-left: 5px;
}

.fullwidth-flex-holder {
    display: flex;
    width: 100%;
}

.halfwidth {
    width: 50%;
}

.grayed-out {
    color: #aaa !important;
}

.thin-hr {
    margin: 10px;
}

.lock-quote-button {
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 3px;
    background-color: #dc3545;
    border: 2px solid #dc3545 !important;
    color: #fff;
    transition: all 150ms linear;
    font-size: 0.8em;
    padding: 0.4em 0.8em;
}

.confirm-holder {
    width:90%;
    align-self: center;
    flex-direction: row;
    display: flex;
    padding-top:20px;
}

.confirm-holder input {
    width: 20px;
    margin-right: 5px;
}

.lock-button {
    font-weight: 800;
    text-transform: uppercase;
    border-radius: 3px;
    color: #fff;
    transition: all 150ms linear;
    font-size: 0.8em;
}

.cancel-lock {
    background-color: #284B63;
    border: #284B63;
}

.confirm-lock:enabled {
    background-color: #dc3545;
    border: 2px solid #dc3545;
    margin-bottom:7px;
}

.confirm-lock:disabled {
    background-color: #dc3546c0;
    border: 2px solid #dc3545c0;
    margin-bottom:7px;
}

.serial-table {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    background-color: #F0F0F4;
}

.serial-table th {
    padding-right: 15px;
    color: #66615b;
}

.serial-table td {
    padding-right: 15px;
}

.serial-table td:first-of-type {
    text-align: center;
}

.serial-title-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
    padding-top: 10px;
}

.serial-holder {
    /* border: 1px solid #DDDDDD; */
    border: 1px solid #F0F0F4;
    background-color: #F0F0F4;
    padding-left: 15px;
    padding-top: 3px;
    border-radius: 5px;
    margin-bottom: 5px;
    overflow-y: auto;
    max-height: 390px;
    width: 80%;
    margin-left: 10%;
}

.tag-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #284b63;
}

.align-me {
    align-content: center;
}

.modal-button-holder {
    padding-top:6px;
}

.modal-button-row {
    display: flex;
    padding-left: 30px;
}

.modal-row-button {
    padding: 8px !important;
    width: 200px !important;
    margin-bottom: 4px !important;
    margin-top: 4px !important;
}

.modal-row-number {
    width: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    font-weight: bold;
    font-size: 16px;
}